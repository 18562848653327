import React, { useCallback, useState, KeyboardEvent } from 'react';
import cl from 'classnames';
import compact from 'lodash/compact';
import values from 'lodash/values';

import { ErrorMessage, IsLoading } from '../../../../../types';

import { MessageFields } from '../../../messagesTypes';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ItemCreateMessageFormSubmit } from './ItemCreateMessageForm.types';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { useTranslate } from '../../../../../common/hooks/useTranslate';
import { useItemCreateMessageForm } from './hooks/useItemCreateMessageForm';

import { ItemCreateMessageClientLocalTime } from './components/ItemCreateMessageClientLocalTime';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { DropzoneWrapperField } from '../../../../../helpers/FormFields/DropzoneWrapperField';
import { Form } from '../../../../../helpers/Form';
import { IconBooleanField } from '../../../../../helpers/FormFields/IconBooleanField';
import { MarkdownHelpModalButton } from '../../modalButtons/MarkdownHelpModalButton';
import { MarkdownHelper } from '../../../../../helpers/MarkdownHelper';
import { ModalColorsField } from '../../../../../helpers/FormFields/ModalColorsField';
import { ModalDropzoneFileAttachmentsField } from '../../../../../helpers/FormFields/ModalDropzoneFileAttachmentsField';
import { ModalSelectAttachmentsField } from '../../../../../helpers/FormFields/ModalSelectAttachmentsField';
import { ModalSelectLifestylesField } from '../../../../../helpers/FormFields/ModalSelectLifestylesField';
import { ModalSelectProductsField } from '../../../../../helpers/FormFields/ModalSelectProductsField';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { PureIconSubmitButtonHelper } from '../../../../../helpers/buttons/PureIconSubmitButtonHelper';
import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TextareaAutosizeField } from '../../../../../helpers/FormFields/TextareaAutosizeField';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import {
  colorsKeys,
  filesKeys,
  lifestylesKeys,
  materialsKeys,
  productsKeys,
  stringsKeys
} from '../../../../../locales/keys';

import { MessagesPermissions } from '../../../messagesConstants';

const MAX_MESSAGE_ROWS = 18;

interface ItemCreateMessageFormProps {
  isLoading?: IsLoading;
  errorMessage?: ErrorMessage;
  projectNanoId?: ProjectNanoID;
  teamNanoId?: TeamNanoID;
  withClientLocalTime?: boolean;
  withFullscreenDropzone?: boolean;
  withPasteFiles?: boolean;
  onSubmit: ItemCreateMessageFormSubmit;
}

function ItemCreateMessageForm({
  isLoading,
  errorMessage,
  projectNanoId,
  teamNanoId,
  withClientLocalTime,
  withFullscreenDropzone,
  withPasteFiles,
  onSubmit
}: ItemCreateMessageFormProps) {
  const [editorToolbarVisible, setEditorToolbarVisible] =
    useState<boolean>(false);

  const [outerFilesLoading, setOuterFilesLoading] = useState<boolean>(false);
  const [fileAttachmentModalOpened, setFileAttachmentsModalOpened] =
    useState<boolean>(false);

  const [previewTextFormatting, setPreviewTextFormatting] =
    useState<boolean>(false);

  const togglePreviewTextFormatting = useCallback<() => void>(
    () => setPreviewTextFormatting((prevState: boolean) => !prevState),
    []
  );

  const handleSetOuterFilesLoading = useCallback<
    (filesLoading: boolean) => void
  >((filesLoading) => {
    setOuterFilesLoading((prev) => {
      if (filesLoading && !prev) {
        setEditorToolbarVisible(true);
      }
      return filesLoading;
    });
  }, []);

  const {
    validationErrors,
    control,
    handleSubmitCreateMessageForm,
    messageBody
  } = useItemCreateMessageForm({ onSubmit, setEditorToolbarVisible });

  const toggleEditorToolbarVisible = useCallback<() => void>(
    () => setEditorToolbarVisible((prevState) => !prevState),
    [setEditorToolbarVisible]
  );

  const handleSentKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.code === 'Enter') {
      e.preventDefault();
      return handleSubmitCreateMessageForm();
    }
  };

  const { t } = useTranslate();

  const allValidationErrorsMessage =
    compact(values(validationErrors).map((i18nErr) => t(i18nErr))).join(', ') ||
    null;

  return (
    <Form onSubmit={handleSubmitCreateMessageForm}>
      <div className="border-t dark:border-gray-700">
        <div className="max-w-screen-lg m-auto">
          <div className="py-2.5 px-2 sm:px-4 bg-white dark:bg-gray-900">
            <div className="flex items-center justify-center gap-x-1 text-2xs text-gray-500 dark:text-gray-400 mb-1">
              {withClientLocalTime && teamNanoId && (
                <ItemCreateMessageClientLocalTime teamNanoId={teamNanoId} />
              )}
            </div>
            {editorToolbarVisible ? (
              <div id="message-editor-toolbar" className="flex space-x-2 mb-2">
                <ModalDropzoneFileAttachmentsField
                  className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0 flex-1 justify-center"
                  name={MessageFields.FILE_ATTACHMENT_IDS}
                  control={control}
                  type="fileAttachments"
                  icon={IconsEnum.PAPER_CLIP_OUTLINE}
                  i18nTitle={filesKeys.attach}
                  i18nSubmitText={filesKeys.attach}
                  modalIcon={IconsEnum.PAPER_CLIP_OUTLINE}
                  tooltipPlacement={TooltipPlacement.TOP}
                  tooltipI18nText={filesKeys.attach}
                  withFullscreenDropzone={withFullscreenDropzone}
                  outerFilesLoading={outerFilesLoading}
                  setModalOpened={setFileAttachmentsModalOpened}
                  withoutInitialFocus
                />
                <ModalSelectAttachmentsField
                  className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0 flex-1 justify-center"
                  name={MessageFields.ATTACHED_FILE_ATTACHMENT_IDS}
                  control={control}
                  icon={IconsEnum.FOLDER_OPEN_OUTLINE}
                  i18nTitle={filesKeys.selectFiles}
                  i18nSubmitText={filesKeys.attach}
                  tooltipPlacement={TooltipPlacement.TOP}
                  tooltipI18nText={filesKeys.attachFromProject}
                  projectNanoId={projectNanoId}
                  withoutInitialFocus
                />
                <ModalSelectProductsField
                  className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0 flex-1 justify-center"
                  name={MessageFields.SELECTED_PRODUCT_IDS}
                  control={control}
                  icon={IconsEnum.CUBE_OUTLINE}
                  iconClassName="h-6 w-6"
                  i18nTitle={productsKeys.attach}
                  i18nSubmitText={productsKeys.attach}
                  modalIcon={IconsEnum.CUBE_OUTLINE}
                  tooltipPlacement={TooltipPlacement.TOP}
                  tooltipI18nText={productsKeys.attach}
                  withoutInitialFocus
                />
                <ModalSelectLifestylesField
                  className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0 flex-1 justify-center"
                  name={MessageFields.SELECTED_LIFESTYLE_IDS}
                  control={control}
                  icon={IconsEnum.RULERS_OUTLINE}
                  iconClassName="h-6 w-6"
                  i18nTitle={lifestylesKeys.attach}
                  i18nSubmitText={lifestylesKeys.attach}
                  modalIcon={IconsEnum.RULERS_OUTLINE}
                  tooltipPlacement={TooltipPlacement.TOP}
                  tooltipI18nText={lifestylesKeys.attach}
                  withoutInitialFocus
                />
                <CheckPermissions
                  action={
                    MessagesPermissions.READ_MESSAGE_ATTACH_MATERIALS_BUTTON
                  }
                >
                  <PureTooltipIconButtonHelper
                    className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0 flex-1 justify-center"
                    icon={IconsEnum.MATERIAL_OUTLINE}
                    tooltipPlacement={TooltipPlacement.TOP}
                    tooltipI18nText={materialsKeys.attach}
                  />
                </CheckPermissions>
                <ModalColorsField
                  className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0 flex-1 justify-center"
                  name={MessageFields.COLORS}
                  control={control}
                  icon={IconsEnum.COLOR_SWATCH_OUTLINE}
                  i18nTitle={colorsKeys.attach}
                  i18nSubmitText={colorsKeys.attach}
                  modalIcon={IconsEnum.COLOR_SWATCH_OUTLINE}
                  tooltipPlacement={TooltipPlacement.TOP}
                  tooltipI18nText={colorsKeys.attach}
                  withoutInitialFocus
                />
              </div>
            ) : null}

            <div className="flex items-end space-x-1">
              <div className="flex gap-1">
                <div>
                  <PureIconButtonHelper
                    className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                    icon={
                      editorToolbarVisible
                        ? IconsEnum.X_CIRCLE_OUTLINE
                        : IconsEnum.PLUS_CIRCLE_OUTLINE
                    }
                    onClick={toggleEditorToolbarVisible}
                  />
                </div>
                <div>
                  <PureTooltipIconButtonHelper
                    tooltipI18nText={stringsKeys.previewTextFormatting}
                    className={cl(
                      'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base focus:ring-offset-0',
                      previewTextFormatting
                        ? 'text-blue-600 hover:text-blue-700 bg-blue-500 bg-opacity-20 hover:bg-opacity-30 dark:text-blue-400 dark:hover:text-blue-300'
                        : 'hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700'
                    )}
                    icon={IconsEnum.HASH_OUTLINE}
                    iconClassName="h-6 w-6"
                    onClick={togglePreviewTextFormatting}
                  />
                </div>
                <div>
                  <MarkdownHelpModalButton />
                </div>
                <CheckPermissions
                  action={
                    MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON
                  }
                >
                  <div>
                    <IconBooleanField
                      name={MessageFields.VISIBLE_FOR_CLIENT}
                      control={control}
                      yesClassName="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-blue-600 hover:text-blue-700 bg-blue-500 bg-opacity-20 hover:bg-opacity-30 dark:text-blue-400 dark:hover:text-blue-300 focus:ring-offset-0"
                      noClassName="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                      yesIcon={IconsEnum.EYE}
                      noIcon={IconsEnum.EYE_CROSSED}
                    />
                  </div>
                </CheckPermissions>
              </div>

              <DropzoneWrapperField
                name={MessageFields.FILE_ATTACHMENT_IDS}
                control={control}
                type="fileAttachments"
                withFullscreenDropzone={
                  withFullscreenDropzone && !fileAttachmentModalOpened
                }
                withPasteFiles={withPasteFiles}
                className="flex flex-1 self-stretch"
                setFilesLoading={handleSetOuterFilesLoading}
              >
                {/*<LexicalEditorField*/}
                {/*  control={control}*/}
                {/*  name={MessageFields.BODY}*/}
                {/*  onKeyDown={handleSentKeyDown}*/}
                {/*/>*/}

                {previewTextFormatting ? (
                  <div
                    className="flex flex-1 overflow-hidden rounded-md self-stretch cursor-text max-h-96"
                    onClick={togglePreviewTextFormatting}
                  >
                    <MarkdownHelper
                      source={messageBody}
                      className="markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap overflow-y-scroll w-full max-h-500 pr-2 pl-4 py-2.5 bg-gray-200 dark:bg-gray-800 text-sm leading-snug"
                    />
                  </div>
                ) : (
                  <TextareaAutosizeField
                    inputWrapperClassName="flex flex-1 focus-within:ring-4 focus-within:ring-blue-300 overflow-hidden rounded-md self-stretch"
                    name={MessageFields.BODY}
                    control={control}
                    className="w-full max-h-500 pr-2 pl-4 py-2.5 bg-gray-100 dark:bg-gray-800 text-sm leading-snug outline-none border-none resize-none"
                    pasteAsMarkdown
                    onKeyDown={handleSentKeyDown}
                    maxRows={MAX_MESSAGE_ROWS}
                  />
                )}
              </DropzoneWrapperField>

              <PureIconSubmitButtonHelper
                className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={isLoading ? IconsEnum.CLOCK_OUTLINE : IconsEnum.ENVELOPE}
                disabled={isLoading || outerFilesLoading}
              />
            </div>
            <AlertMessage
              className="mt-2"
              message={allValidationErrorsMessage || errorMessage}
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

export default ItemCreateMessageForm;
