import map from 'lodash/map';

import {
  CheckedHashItem,
  OnSetCheckedIds
} from '../../../../../../../../../common/hooks/useTableCheckable';

import {
  UserCheckboxField,
  UserCheckboxFieldUserType
} from '../UserCheckboxField';

interface UsersCheckboxListProps {
  users: UserCheckboxFieldUserType[];
  checkedHash: CheckedHashItem;
  onSetCheckedIds: OnSetCheckedIds;
}

function UsersCheckboxList({
  users,
  checkedHash,
  onSetCheckedIds
}: UsersCheckboxListProps) {
  if (!users.length) return null;

  return (
    <ul>
      {map(users, (user) => (
        <li key={user.id}>
          <UserCheckboxField
            user={user}
            checked={checkedHash[user.id]}
            onCheck={onSetCheckedIds}
          />
        </li>
      ))}
    </ul>
  );
}

export default UsersCheckboxList;
