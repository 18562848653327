import { MessageFields, MessageUUID } from '../../../../../messagesTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

const defaultValues: ItemCreateMessageFormData = {
  uuid: '' as MessageUUID,
  body: '',
  visibleForClient: false,
  fileAttachmentIds: [],
  attachedFileAttachmentIds: [],
  selectedProductIds: [],
  selectedLifestyleIds: [],
  colors: []
};

import { ItemCreateMessageFormSubmit } from '../../ItemCreateMessageForm.types';

import { ItemCreateMessageFormData } from './useItemCreateMessageForm.types';

import { itemCreateMessageFormValidationSchema } from './useItemCreateMessageForm.schema';

import { generateUuid } from '../../../../../../../utils/generateUuid';

interface useItemCreateMessageFormProps {
  onSubmit: ItemCreateMessageFormSubmit;
  setEditorToolbarVisible: (value: boolean) => void;
}

function useItemCreateMessageForm({
  onSubmit,
  setEditorToolbarVisible
}: useItemCreateMessageFormProps) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    getValues
  } = useReactHookForm<ItemCreateMessageFormData>({
    defaultValues,
    schema: itemCreateMessageFormValidationSchema
  });

  return {
    validationErrors: {
      bodyValidationError: errors?.body?.message
    },
    control,
    resetForm,
    handleSubmitReactHookForm,
    handleSubmitCreateMessageForm: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: (data) => {
        resetForm();
        return onSubmit({ ...data, uuid: generateUuid() }).then(() => {
          setEditorToolbarVisible(false);
        });
      }
    }),
    registerFields: {
      registerBody: register(MessageFields.BODY),
      registerVisibleForClient: register(MessageFields.VISIBLE_FOR_CLIENT)
    },
    messageBody: getValues(MessageFields.BODY)
  };
}

export default useItemCreateMessageForm;
