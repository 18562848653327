import React, { useCallback, useEffect, useState, useRef } from 'react';
import max from 'lodash/max';
import size from 'lodash/size';

import {
  DropzoneWrapperHelperFileIds,
  DropzoneWrapperHelperRequiredProps
} from './DropzoneWrapperHelper.types';

import { useToastNotification } from '../../../common/hooks/useToastNotification';

import {
  S3MultipartDropzoneWrapper,
  S3MultipartDropzoneWrapperOnFilesAccepted,
  S3MultipartDropzoneWrapperOnFileUploaded,
  S3MultipartDropzoneWrapperOnFileFailed
} from '../../dropzone/S3MultipartDropzoneWrapper';

import { filesKeys } from '../../../locales/keys';

export type DropzoneWrapperHelperProps = {
  value: DropzoneWrapperHelperFileIds;
  onChange: (value: DropzoneWrapperHelperFileIds) => void;
};

function DropzoneWrapperHelper({
  children,
  type,
  disabled,
  maxFiles,
  preventMaxFilesOverload,
  withFullscreenDropzone,
  withPasteFiles,
  className,
  value,
  onChange,
  setFilesLoading
}: DropzoneWrapperHelperRequiredProps & DropzoneWrapperHelperProps) {
  const valueRef = useRef<DropzoneWrapperHelperFileIds>(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const [loadingFilesAmount, setLoadingFilesAmount] = useState<number>(0);
  useEffect(() => {
    setFilesLoading(loadingFilesAmount !== 0);
  }, [setFilesLoading, loadingFilesAmount]);

  const { showToastI18nNotification: showToastOnFilesDropped } =
    useToastNotification({
      i18nMessage: filesKeys.startFilesUploading,
      appearance: 'success'
    });

  const handleFilesDropped =
    useCallback<S3MultipartDropzoneWrapperOnFilesAccepted>(
      (files) => {
        setLoadingFilesAmount((prev) => prev + size(files));
        showToastOnFilesDropped();
      },
      [showToastOnFilesDropped]
    );

  const { showToastI18nNotification: showToastOnFileUploaded } =
    useToastNotification({
      i18nMessage: filesKeys.fileUploaded,
      appearance: 'success'
    });

  const handleFileUploaded =
    useCallback<S3MultipartDropzoneWrapperOnFileUploaded>(
      (id, uploadedId) => {
        onChange([...valueRef.current, uploadedId]);
        setLoadingFilesAmount((prev) => max([0, prev - 1]));
        showToastOnFileUploaded();
      },
      [onChange, showToastOnFileUploaded]
    );

  const { showToastI18nNotification: showToastOnFileFailed } =
    useToastNotification({
      i18nMessage: filesKeys.failedToUpload,
      appearance: 'error'
    });

  const handleFileFailed =
    useCallback<S3MultipartDropzoneWrapperOnFileFailed>(() => {
      setLoadingFilesAmount((prev) => max([0, prev - 1]));
      showToastOnFileFailed();
    }, [showToastOnFileFailed]);

  return (
    <S3MultipartDropzoneWrapper
      type={type}
      disabled={disabled}
      maxFiles={maxFiles}
      activeFilesCount={size(value)}
      preventMaxFilesOverload={preventMaxFilesOverload}
      withFullscreenDropzone={withFullscreenDropzone}
      withPasteFiles={withPasteFiles}
      className={className}
      onFilesAccepted={handleFilesDropped}
      onFileUploaded={handleFileUploaded}
      onFileFailed={handleFileFailed}
    >
      {children}
    </S3MultipartDropzoneWrapper>
  );
}

export default DropzoneWrapperHelper;
