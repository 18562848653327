import { useCallback } from 'react';

import { UserCheckboxFieldUserType } from './UserChackboxField.types';
import { OnSetCheckedIds } from '../../../../../../../../../common/hooks/useTableCheckable';

import { Checkbox } from '../../../../../../../../../helpers/Checkbox';
import { UserAvatar } from '../../../../../../../../common/helpers/UserAvatar';

interface UserCheckboxFieldProps {
  user: UserCheckboxFieldUserType;
  checked: boolean;
  onCheck: OnSetCheckedIds;
}

function UserCheckboxField({ user, checked, onCheck }: UserCheckboxFieldProps) {
  const handleChange = useCallback(() => onCheck(user.id), [onCheck, user.id]);

  const authorId = `author-${user.id}`;

  return (
    <label
      htmlFor={authorId}
      className="flex items-center gap-2 pl-1 pr-2 py-1 hover:bg-gray-500/20 cursor-pointer rounded-md"
    >
      <div className="flex items-center">
        <Checkbox id={authorId} onChange={handleChange} checked={checked} />
      </div>
      <div className="h-6 w-6 rounded-full bg-green-100 text-green-800 dark:text-green-50 dark:bg-green-900 flex items-center justify-center overflow-hidden shrink-0">
        <UserAvatar user={user} className="w-full h-full block object-cover" />
      </div>
      <span className="text-sm truncate max-w-64">{user.fullName}</span>
    </label>
  );
}

export default UserCheckboxField;
