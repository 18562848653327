import {
  PinnedMessagesListMessageItems,
  PinnedMessagesListMessagesTotalCount
} from '../../list/PinnedMessagesListMessage';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../messagesTypes';
import {
  FetchUsersErrorMessage,
  FetchUsersFetched,
  FetchUsersTotalCount
} from '../../../../users/usersTypes';
import { IconsEnum } from '../../../../../assets/icons/types';
import { UserCheckboxFieldUserType } from './components/ItemMessagesHeaderUsersFilter/components/UserCheckboxField';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../list/ItemMessagesList';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { ItemMessagesHeaderUsersFilter } from './components/ItemMessagesHeaderUsersFilter';
import { ItemMessagesHeaderVisibleForClientsFilter } from './components/ItemMessagesHeaderVisibleForClientsFilter';
import { MessagesListSimpleModalButton } from '../../modalButtons/MessagesListSimpleModalButton';

import { MessagesPermissions } from '../../../messagesConstants';
import { messagesKeys, words } from '../../../../../locales/keys';

interface ItemMessagesHeaderProps {
  pinnedMessages: PinnedMessagesListMessageItems;
  pinnedMessagesCacheKey: FetchMessagesCacheKey;
  pinnedMessagesTotalCount: PinnedMessagesListMessagesTotalCount;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  users: UserCheckboxFieldUserType[];
  usersError: FetchUsersErrorMessage;
  usersFetched: FetchUsersFetched;
  usersTotalCount: FetchUsersTotalCount;
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
}

function ItemMessagesHeader({
  pinnedMessages,
  pinnedMessagesCacheKey,
  pinnedMessagesTotalCount,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  users,
  usersError,
  usersFetched,
  usersTotalCount,
  messagesFilters,
  filterMessages
}: ItemMessagesHeaderProps) {
  return (
    <div className="border-b dark:border-gray-700">
      <div className="max-w-screen-lg m-auto">
        <div className="flex items-center px-1 sm:px-3 py-0.5 text-gray-500 dark:text-gray-400 gap-2">
          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGES_HEADER_AUTHORS_FILTER}
          >
            <ItemMessagesHeaderUsersFilter
              users={users}
              usersError={usersError}
              usersFetched={usersFetched}
              usersTotalCount={usersTotalCount}
              messagesFilters={messagesFilters}
              filterMessages={filterMessages}
            />
          </CheckPermissions>
          <div className="flex gap-1 flex-1">
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGES_HEADER_VISIBLE_FOR_CLIENTS_FILTER
              }
            >
              <ItemMessagesHeaderVisibleForClientsFilter
                messagesFilters={messagesFilters}
                filterMessages={filterMessages}
              />
            </CheckPermissions>
          </div>
          <div className="flex gap-1">
            <CheckPermissions
              action={MessagesPermissions.READ_MESSAGES_HEADER_PINNED_BUTTON}
            >
              <MessagesListSimpleModalButton
                className="py-1 pl-1 pr-2 space-x-1 rounded inline-flex items-center whitespace-nowrap text-xs leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0"
                i18nTitle={messagesKeys.pinnedMessages}
                i18nButtonTextClassName="hidden md:inline"
                i18nButtonText={words.pinned}
                icon={IconsEnum.PIN}
                iconClassName="w-4 h-4 mr-1"
                messages={pinnedMessages}
                messagesCacheKey={pinnedMessagesCacheKey}
                messagesTotalCount={pinnedMessagesTotalCount}
                forwardMessageProjectUuid={forwardMessageProjectUuid}
                forwardMessageTaskUuid={forwardMessageTaskUuid}
              />
            </CheckPermissions>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesHeader;
