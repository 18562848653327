import { ClassName, I18nText } from '../../../../../types';
import {
  FetchMessagesCacheKey,
  FetchMessagesTotalCount,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../messagesTypes';

import { usePreventModalClose } from '../../../../../helpers/modals/hooks/usePreventModalClose';

import {
  ItemMessagesListMessage,
  ItemMessagesListMessageItem
} from '../../list/ItemMessagesListMessage';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { IconsEnum } from '../../../../../assets/icons/types';
import { Translate } from '../../../../../helpers/Translate';
import { Icon } from '../../../../../helpers/Icon';

type MessagesListSimpleModalButtonMessagesItems = ItemMessagesListMessageItem[];

interface MessagesListSimpleModalButtonProps {
  i18nTitle: I18nText;
  i18nButtonText?: I18nText;
  i18nButtonTextClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  messages: MessagesListSimpleModalButtonMessagesItems;
  messagesCacheKey: FetchMessagesCacheKey;
  messagesTotalCount?: FetchMessagesTotalCount;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  className?: ClassName;
}

function MessagesListSimpleModalButton({
  i18nTitle,
  i18nButtonText,
  i18nButtonTextClassName,
  icon,
  iconClassName,
  messages,
  messagesCacheKey,
  messagesTotalCount,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  className
}: MessagesListSimpleModalButtonProps) {
  const { preventModalClose, togglePreventModalClose } = usePreventModalClose();

  return (
    <SimpleModalButton
      className={className}
      i18nTitle={i18nTitle}
      buttonChildren={
        <>
          {icon && <Icon className={iconClassName} icon={icon} />}
          {messagesTotalCount && <span>{messagesTotalCount}</span>}
          {i18nButtonText && (
            <span className={i18nButtonTextClassName}>
              <Translate id={i18nButtonText} />
            </span>
          )}
        </>
      }
      icon={icon}
      iconClassName={iconClassName}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      withCloseButton
      withoutCancelButton
      withoutSubmitButton
      preventModalClose={preventModalClose}
    >
      {messages.map((message) => (
        <ItemMessagesListMessage
          key={message.uuid}
          message={message}
          messagesCacheKey={messagesCacheKey}
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
          togglePreventModalClose={togglePreventModalClose}
        />
      ))}
    </SimpleModalButton>
  );
}

export default MessagesListSimpleModalButton;
