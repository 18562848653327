import { useCallback } from 'react';

import { MessageMessageTypes } from '../../../../../messagesTypes';

import { CreateMockMessageFromFormDataAction } from './useCreateMockMessageWithFormData.types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { GenerateId } from '../../../../../../../utils/GenerateId';

function useCreateMockMessageWithFormData() {
  const currentUser = useCurrentUser();

  const createMockMessageFromFormData =
    useCallback<CreateMockMessageFromFormDataAction>(
      ({ formData, task, project }) => {
        const baseMessage = {
          formData: formData,
          id: GenerateId.generate(),
          uuid: formData.uuid,
          body: formData.body,
          messageType: MessageMessageTypes.FRONTEND_SENDING_MESSAGE,
          visibleForClient: currentUser.get('client')
            ? true
            : formData.visibleForClient,
          colors: formData.colors,
          isResult: false,
          favorite: false,
          flagged: false,
          createdAt: new Date().toISOString(),
          messageLikes: [],
          fileAttachments: [],
          selectedProducts: [],
          selectedLifestyles: [],
          selectedMaterials: [],
          user: {
            uuid: currentUser.get('uuid'),
            nanoId: currentUser.get('nanoId'),
            fullName: currentUser.get('fullName'),
            client: currentUser.get('client'),
            image: currentUser.get('image'),
            currentTeam: currentUser.get('currentTeam')
          }
        };

        if (task) {
          return { ...baseMessage, task };
        }

        if (project) {
          return { ...baseMessage, project };
        }

        return baseMessage;
      },
      [currentUser]
    );

  return createMockMessageFromFormData;
}

export default useCreateMockMessageWithFormData;
