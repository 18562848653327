import React from 'react';
import { Controller } from 'react-hook-form';

import { DropzoneWrapperHelper } from '../../dropzone/DropzoneWrapperHelper';

import { DropzoneWrapperFieldProps } from './DropzoneWrapperField.types';

function DropzoneWrapperField<T>({
  children,
  control,
  disabled,
  name,
  type,
  maxFiles,
  className,
  preventMaxFilesOverload,
  withFullscreenDropzone,
  withPasteFiles,
  setFilesLoading
}: DropzoneWrapperFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <DropzoneWrapperHelper
          type={type}
          value={value as string[]}
          disabled={disabled}
          maxFiles={maxFiles}
          preventMaxFilesOverload={preventMaxFilesOverload}
          withFullscreenDropzone={withFullscreenDropzone}
          withPasteFiles={withPasteFiles}
          className={className}
          onChange={onChange}
          setFilesLoading={setFilesLoading}
        >
          {children}
        </DropzoneWrapperHelper>
      )}
    />
  );
}

export default DropzoneWrapperField;
