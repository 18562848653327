import React from 'react';

import { TeamNanoID } from '../../../../../../teams/teamsTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FETCH_TEAM_TIME_ZONE_QUERY,
  FetchTeamTimeZoneQueryResponse
} from '../../../../../../teams/queries/fetchTeamTimeZone.query';

import { useTeam } from '../../../../../../teams/hooks/useTeam';

import { Translate } from '../../../../../../../helpers/Translate';
import { DateHelper } from '../../../../../../../helpers/DateHelper';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { Icon } from '../../../../../../../helpers/Icon';

import { TeamCache } from '../../../../../../teams/TeamCache';
import { words } from '../../../../../../../locales/keys';

import { getTeamLocalTime } from '../../../../../../teams/utils/getTeamLocalTime';

interface ItemCreateMessageClientLocalTimeProps {
  teamNanoId: TeamNanoID;
}

function ItemCreateMessageClientLocalTime({
  teamNanoId
}: ItemCreateMessageClientLocalTimeProps) {
  const { team, teamFetched, teamError } =
    useTeam<FetchTeamTimeZoneQueryResponse>({
      cacheKey: TeamCache.showCompanyTimeZoneCacheKey(),
      query: FETCH_TEAM_TIME_ZONE_QUERY,
      uuid: teamNanoId,
      options: {
        enabled: !!teamNanoId,
        enabledPlaceholder: !!teamNanoId
      }
    });

  if ((!team && !teamFetched) || !team?.timeZone) {
    return null;
  }

  const clientLocalTime = getTeamLocalTime(team?.timeZone);

  return (
    <div className="flex items-center justify-center gap-x-1 text-2xs text-gray-500 dark:text-gray-400 mb-1">
      <Icon icon={IconsEnum.CLOCK} className="w-4 h-4 shrink-0" />
      <span>
        <Translate id={words.its} />{' '}
        <DateHelper date={clientLocalTime} customFormat="HH:mm" />{' '}
        <Translate id={words.for} /> {team?.name}
      </span>
      <AlertMessage message={teamError} />
    </div>
  );
}

export default ItemCreateMessageClientLocalTime;
