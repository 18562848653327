import isEmpty from 'lodash/isEmpty';

import { ItemMessagesListMessageItem } from '../../../ItemMessagesListMessage';

function defineIsAuthorSame(
  firstMessage: ItemMessagesListMessageItem,
  secondMessage: ItemMessagesListMessageItem
): boolean {
  const isSameAuthor =
    secondMessage?.user?.uuid === firstMessage.user?.uuid &&
    isEmpty(firstMessage.fileAttachments) &&
    isEmpty(firstMessage.selectedProducts) &&
    isEmpty(firstMessage.selectedLifestyles) &&
    isEmpty(firstMessage.selectedMaterials) &&
    isEmpty(secondMessage?.fileAttachments) &&
    isEmpty(secondMessage?.selectedProducts) &&
    isEmpty(secondMessage?.selectedLifestyles);

  return isSameAuthor;
}

export default defineIsAuthorSame;
