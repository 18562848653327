import { useCallback, useEffect, useState } from 'react';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';

import {
  ItemMessagesListMessages,
  ItemMessagesListMessagesFetched
} from '../../ItemMessagesList.types';

import {
  PinnedMessagesListMessageItem,
  PinnedMessagesListMessageItems
} from '../../../PinnedMessagesListMessage';
import { ItemMessagesListMessageItem } from '../../../ItemMessagesListMessage';

interface ItemMessagesListOptions {
  messages: ItemMessagesListMessages;
  messagesFetched: ItemMessagesListMessagesFetched;
  pinnedMessages: PinnedMessagesListMessageItems;
  sendingMessages?: ItemMessagesListMessages;
}

function useItemMessagesList({
  messages,
  messagesFetched,
  pinnedMessages,
  sendingMessages
}: ItemMessagesListOptions) {
  const [isDateShown, setIsDateShown] = useState<boolean>(false);

  const toggleShowDate = useCallback<() => void>(() => {
    setIsDateShown((prevVal) => !prevVal);
  }, []);

  const sortedMessages = concat(
    sortBy<ItemMessagesListMessageItem>(messages, (message) =>
      parseInt(message.id as string, 10)
    ),
    sendingMessages
  );

  const sortedPinnedMessages = sortBy<PinnedMessagesListMessageItem>(
    pinnedMessages,
    (pinnedMessage) => parseInt(pinnedMessage.id as string, 10)
  );

  const lastMessageUuid =
    last<ItemMessagesListMessageItem>(sortedMessages)?.uuid;

  const [messagesScrollElement, setMessagesScrollElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    if (messagesScrollElement) {
      const scrollToLastMessage = () => {
        const item = document.getElementById(`message-${lastMessageUuid}`);

        const itemPosition = item?.getBoundingClientRect();
        const scrollElementPosition =
          messagesScrollElement.getBoundingClientRect();

        if (item && itemPosition.bottom > scrollElementPosition.bottom) {
          messagesScrollElement?.scrollTo({
            top: messagesScrollElement?.scrollHeight
          });
        }
      };

      if (messagesFetched) {
        requestAnimationFrame(scrollToLastMessage);
      }
    }
  }, [lastMessageUuid, messagesFetched, messagesScrollElement]);

  return {
    isDateShown,
    sortedPinnedMessages,
    sortedMessages,
    messagesScrollElement,
    setMessagesScrollElement,
    toggleShowDate
  };
}

export default useItemMessagesList;
