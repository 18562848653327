import * as yup from 'yup';
import { formsErrors } from '../../../../../../../locales/keys/forms/errors';

import { MessageFields } from '../../../../../messagesTypes';

export const itemCreateMessageFormValidationSchema = yup.object({
  [MessageFields.BODY]: yup.string().when([MessageFields.FILE_ATTACHMENT_IDS], {
    is: (value: string[]) => !value || !value?.length,
    then: yup.string().when([MessageFields.ATTACHED_FILE_ATTACHMENT_IDS], {
      is: (value: string[]) => !value || !value?.length,
      then: yup.string().when([MessageFields.SELECTED_PRODUCT_IDS], {
        is: (value: string[]) => !value || !value?.length,
        then: yup.string().when([MessageFields.SELECTED_LIFESTYLE_IDS], {
          is: (value: string[]) => !value || !value?.length,
          then: yup.string().when([MessageFields.COLORS], {
            is: (value: string[]) => !value || !value?.length,
            then: yup.string().required(formsErrors.message.required)
          })
        })
      })
    })
  })
});
