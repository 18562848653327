import React, { useCallback, useState } from 'react';
import some from 'lodash/some';

import { SimpleModalButton } from '../../buttons/SimpleModalButton';

import { DropzoneHelperFiles, DropzoneHelperFileIds } from '../DropzoneHelper';

import { DropzoneFileAttachmentsHelper } from '../DropzoneFileAttachmentsHelper';

import { DropzoneFileAttachmentsModalButtonRequiredProps } from './DropzoneFileAttachmentsModalButton.types';
import { I18nText } from '../../../types';

interface DropzoneFileAttachmentsModalButtonBaseProps {
  value: string[];
  disabled?: boolean;
  onSubmit: (value: string[]) => void;
}

interface DropzoneFileAttachmentsModalButtonWithTextProps {
  buttonText: string;
  i18nText?: never;
}

interface DropzoneFileAttachmentsModalButtonWithI18nTextProps {
  buttonText?: never;
  i18nText: I18nText;
}

type DropzoneFileAttachmentsModalButtonProps =
  DropzoneFileAttachmentsModalButtonBaseProps &
    (
      | DropzoneFileAttachmentsModalButtonWithTextProps
      | DropzoneFileAttachmentsModalButtonWithI18nTextProps
    );

function DropzoneFileAttachmentsModalButton({
  type,
  value,
  disabled,
  className,
  i18nTitle,
  icon,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  i18nText,
  buttonText,
  withFullscreenDropzone,
  onSubmit,
  setModalOpened,
  withoutInitialFocus
}: DropzoneFileAttachmentsModalButtonProps &
  DropzoneFileAttachmentsModalButtonRequiredProps) {
  const [opened, setOpened] = useState<boolean>(false);
  const handleSetOpened = useCallback<(opened: boolean) => void>(
    (opened) => {
      setOpened(opened);
      setModalOpened?.(opened);
    },
    [setModalOpened]
  );

  const [editableValue, setEditableValue] =
    useState<DropzoneHelperFileIds>(value);

  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  const handleChangeFiles = useCallback<(files: DropzoneHelperFiles) => void>(
    (files) => {
      setLoadingFiles(some(files, (file) => file.state === 'processing'));
    },
    []
  );

  const handleOpen = useCallback<() => void>(() => {
    handleSetOpened(true);
    setEditableValue(value);
    setLoadingFiles(false);
  }, [handleSetOpened, value]);

  const handleCancel = useCallback<() => void>(() => {
    setEditableValue(value);
    setLoadingFiles(false);
  }, [value]);

  const handleClose = useCallback<() => void>(() => {
    handleSetOpened(false);
    setLoadingFiles(false);
  }, [handleSetOpened]);

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    onSubmit(editableValue);
  }, [onSubmit, editableValue]);

  return (
    <SimpleModalButton
      className={className}
      childrenClassName="flex-1 overflow-y-auto p-4"
      i18nTitle={i18nTitle}
      icon={icon}
      modalIcon={modalIcon}
      {...(i18nText ? { i18nText } : { buttonText })}
      i18nSubmitText={i18nSubmitText}
      disabled={disabled || (opened && loadingFiles)}
      isLoading={disabled}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onClose={handleClose}
      onSubmit={handleSubmit}
      withoutInitialFocus={withoutInitialFocus}
    >
      <div className="mt-5">
        <DropzoneFileAttachmentsHelper
          type={type}
          value={value}
          disabled={disabled}
          withFullscreenDropzone={withFullscreenDropzone}
          onChange={setEditableValue}
          onChangeFiles={handleChangeFiles}
        />
      </div>
    </SimpleModalButton>
  );
}

export default DropzoneFileAttachmentsModalButton;
