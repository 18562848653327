import React, { Fragment, memo } from 'react';

import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../messagesTypes';

import {
  ItemMessagesListHasNextMessagesPage,
  ItemMessagesListLoadMoreMessages,
  ItemMessagesListMessages,
  ItemMessagesListMessagesErrorMessage,
  ItemMessagesListMessagesFetched,
  ItemMessagesListMessagesFetchingNextPage,
  ItemMessagesListMessagesIsPlaceholderData,
  ItemMessagesListOnRemoveSendingMessage
} from './ItemMessagesList.types';

import { useItemMessagesList } from './hooks/useItemMessagesList';

import { ItemMessagesListMessage } from '../ItemMessagesListMessage';
import { ItemMessagesListMessageCreatedAt } from '../ItemMessagesListMessage/components/ItemMessagesListMessageCreatedAt';

import {
  PinnedMessagesListMessageItems,
  PinnedMessagesListMessagesErrorMessage,
  PinnedMessagesListMessagesFetched,
  PinnedMessagesListMessagesTotalCount
} from '../PinnedMessagesListMessage';

import { ScrollToBottom } from '../../../../../helpers/scrolls/ScrollToBottom';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { defineIsAuthorSame } from './utils/defineIsAuthorSame';
import { getDifferenceInMinutes } from '../../../../../utils/getDifferenceInMinutes';

import { messagesKeys } from '../../../../../locales/keys';

interface ItemMessagesListProps {
  messages: ItemMessagesListMessages;
  messagesCacheKey: FetchMessagesCacheKey;
  messagesFetched: ItemMessagesListMessagesFetched;
  messagesFetchingNextPage: ItemMessagesListMessagesFetchingNextPage;
  messagesErrorMessage: ItemMessagesListMessagesErrorMessage;
  messagesIsPlaceholderData: ItemMessagesListMessagesIsPlaceholderData;
  hasNextMessagesPage: ItemMessagesListHasNextMessagesPage;
  loadMoreMessages: ItemMessagesListLoadMoreMessages;
  pinnedMessages: PinnedMessagesListMessageItems;
  pinnedMessagesCacheKey: FetchMessagesCacheKey;
  pinnedMessagesFetched: PinnedMessagesListMessagesFetched;
  pinnedMessagesErrorMessage: PinnedMessagesListMessagesErrorMessage;
  pinnedMessagesTotalCount: PinnedMessagesListMessagesTotalCount;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  sendingMessages?: ItemMessagesListMessages;
  onRemoveSendingMessage?: ItemMessagesListOnRemoveSendingMessage;
}

function ItemMessagesList({
  messages,
  messagesCacheKey,
  messagesErrorMessage,
  messagesFetched,
  messagesFetchingNextPage,
  messagesIsPlaceholderData,
  hasNextMessagesPage,
  loadMoreMessages,
  pinnedMessages,
  pinnedMessagesErrorMessage,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  sendingMessages,
  onRemoveSendingMessage
}: ItemMessagesListProps) {
  const {
    isDateShown,
    sortedMessages,
    messagesScrollElement,
    setMessagesScrollElement,
    toggleShowDate
  } = useItemMessagesList({
    messages,
    messagesFetched,
    pinnedMessages,
    sendingMessages
  });

  return (
    <div className="flex-1 relative">
      <div
        id="message-thread"
        className="absolute inset-0 overflow-y-auto overflow-x-hidden"
        ref={setMessagesScrollElement}
      >
        <div className="flex flex-col min-h-full justify-end m-auto max-w-screen-lg isolate">
          <div className="py-4 px-2 sm:px-4">
            <AlertMessage
              addClassName="mt-4"
              message={messagesErrorMessage || pinnedMessagesErrorMessage}
            />
            <LoadingSkeleton
              loaded={messagesIsPlaceholderData || messagesFetched}
            >
              <FetchMoreInfiniteButtonHelper
                errorMessage={messagesErrorMessage}
                isLoading={messagesFetchingNextPage}
                hasNextPage={hasNextMessagesPage}
                i18nText={messagesKeys.showOlder}
                className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                wrapperClassName="text-center mt-8"
                placement={FetchMoreInfiniteButtonHelperPlacement.TOP}
                onFetchMore={loadMoreMessages}
              />
              {sortedMessages.map((message, index) => (
                <Fragment key={message.uuid}>
                  {!sortedMessages[index - 1] ||
                  getDifferenceInMinutes(
                    message.createdAt,
                    sortedMessages[index - 1]?.createdAt
                  ) > 15 ||
                  isDateShown ? (
                    <ItemMessagesListMessageCreatedAt
                      uuid={message.uuid}
                      createdAt={message.createdAt}
                      onClick={toggleShowDate}
                    />
                  ) : null}
                  <ItemMessagesListMessage
                    message={message}
                    messagesCacheKey={messagesCacheKey}
                    forwardMessageProjectUuid={forwardMessageProjectUuid}
                    forwardMessageTaskUuid={forwardMessageTaskUuid}
                    prevSameAuthor={
                      defineIsAuthorSame(message, sortedMessages[index - 1]) &&
                      getDifferenceInMinutes(
                        message.createdAt,
                        sortedMessages[index - 1]?.createdAt
                      ) <= 15 &&
                      message.messageType ===
                        sortedMessages[index - 1]?.messageType
                    }
                    nextSameAuthor={
                      defineIsAuthorSame(message, sortedMessages[index + 1]) &&
                      getDifferenceInMinutes(
                        message.createdAt,
                        sortedMessages[index + 1]?.createdAt
                      ) <= 15 &&
                      message.messageType ===
                        sortedMessages[index + 1]?.messageType
                    }
                    onRemoveSendingMessage={onRemoveSendingMessage}
                  />
                </Fragment>
              ))}
            </LoadingSkeleton>
          </div>
        </div>
      </div>
      {messagesFetched ? (
        <ScrollToBottom
          scrollContainerElement={messagesScrollElement}
          i18nText={messagesKeys.toLatestMessages}
        />
      ) : null}
    </div>
  );
}

export default memo<ItemMessagesListProps>(ItemMessagesList);
